var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.items,"fields":[{ key: 'section', label: _vm.column_name, sortable: true }].concat(_vm.fields),"small":"","responsive":"","thead-class":"custom-header small","table-class":"simple-report-table small","bordered":""},scopedSlots:_vm._u([{key:"head()",fn:function(data){return [_c('div',{staticClass:"custom-table-header"},[[(data.field.label.toLowerCase().includes('nota'))?[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.v-secondary.noninteractive",value:('(Promedio)'),expression:"'(Promedio)'",modifiers:{"top":true,"v-secondary":true,"noninteractive":true}}]},[_vm._v(" "+_vm._s(data.field.label.split(" ")[0])+" ")])]:(data.field.label.toLowerCase().includes('ponderación'))?[_c('div',[_vm._v(" "+_vm._s(data.field.label.split(" ")[0])+" ")])]:(
              data.field.label.toLowerCase().includes('desviación estándar')
            )?[_c('div',[_vm._v(" "+_vm._s(data.field.label.split(" ")[0] + " " + data.field.label.split(" ")[1])+" ")])]:(
              data.field.label.toLowerCase() == 'evaluación' ||
              data.field.label.toLowerCase() == 'instrumento'
            )?[_c('div',[_vm._v("NOMBRE")])]:[_vm._v(" "+_vm._s(data.field.label)+" ")]]],2)]}},{key:"cell()",fn:function(data){return [[(
            data.field.label.toLowerCase().includes('nota') ||
            data.field.label.toLowerCase().includes('promedio')
          )?_c('div',{class:{
            'bg-red': data.value <= _vm.chartdata.extra_data.grades_ranges[0][2],
            'bg-yellow':
              data.value >= _vm.chartdata.extra_data.grades_ranges[1][1] &&
              data.value <= _vm.chartdata.extra_data.grades_ranges[1][2],
            'bg-green':
              data.value >= _vm.chartdata.extra_data.grades_ranges[2][1],
            'bg-transparent': data.value == '',
          }},[_c('span',[_vm._v(" "+_vm._s(data.value)+" ")])]):_c('div',[_vm._v(" "+_vm._s(data.value)+" ")])]]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }