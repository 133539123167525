<template>
  <div>
    <b-table
      striped
      hover
      :items="items"
      :fields="
        [{ key: 'section', label: column_name, sortable: true }].concat(fields)
      "
      small
      responsive
      thead-class="custom-header small"
      table-class="simple-report-table small"
      bordered
    >
      <template v-slot:head()="data">
        <div class="custom-table-header">
          <template>
            <template v-if="data.field.label.toLowerCase().includes('nota')">
              <div v-b-tooltip.top.v-secondary.noninteractive="'(Promedio)'">
                {{ data.field.label.split(" ")[0] }}
              </div>
            </template>
            <template
              v-else-if="data.field.label.toLowerCase().includes('ponderación')"
            >
              <div>
                {{ data.field.label.split(" ")[0] }}
              </div>
            </template>
            <template
              v-else-if="
                data.field.label.toLowerCase().includes('desviación estándar')
              "
            >
              <div>
                {{
                  data.field.label.split(" ")[0] +
                  " " +
                  data.field.label.split(" ")[1]
                }}
              </div>
            </template>
            <template
              v-else-if="
                data.field.label.toLowerCase() == 'evaluación' ||
                data.field.label.toLowerCase() == 'instrumento'
              "
            >
              <div>NOMBRE</div>
            </template>
            <template v-else>
              {{ data.field.label }}
            </template>
          </template>
        </div>
      </template>
      <template v-slot:cell()="data">
        <template>
          <div
            v-if="
              data.field.label.toLowerCase().includes('nota') ||
              data.field.label.toLowerCase().includes('promedio')
            "
            :class="{
              'bg-red': data.value <= chartdata.extra_data.grades_ranges[0][2],
              'bg-yellow':
                data.value >= chartdata.extra_data.grades_ranges[1][1] &&
                data.value <= chartdata.extra_data.grades_ranges[1][2],
              'bg-green':
                data.value >= chartdata.extra_data.grades_ranges[2][1],
              'bg-transparent': data.value == '',
            }"
          >
            <span>
              {{ data.value }}
            </span>
          </div>

          <div v-else>
            {{ data.value }}
          </div>
        </template>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: "MatterGradesPerSectionTable",
  props: ["chartdata", "items", "fields", "column_name", "students_data_case"],
};
</script>

<style scoped>
.bg-red,
.bg-yellow,
.bg-green {
  height: 100% !important;
  vertical-align: middle;
  bottom: 50% !important;
  padding-top: 5px;
}
.bg-red {
  background-color: #f19292 !important;
}
.bg-yellow {
  background-color: #fff1ac !important;
}
.bg-green {
  background-color: #bbeaa6 !important;
}
.bg-transparent {
  background-color: transparent !important;
}
</style>